import _ from 'lodash'
import { FIELD_PROSPECTING } from '../constants'
import { convertAreaForDisplay } from './common'
const CARBON_TO_CO2E = 44 / 12
/**
 * This function returns the rating tier band color hexcode for a given sequestration rating
 * that falls within the range of that tier band.
 * Calculations are always performed on the sequestration rating in metric units, even
 * if the displayed units are imperial.
 */

export const getTierColor = (socRateValueInHectares, ratingTiers) => {
  const tierValidations = Object.entries(ratingTiers)
  const socCo2eRateInHectares = convertCarbonToCO2equivalent(
    socRateValueInHectares
  )

  if (socCo2eRateInHectares === 0) {
    return FIELD_PROSPECTING.TIER_COLOR_CONFIG.DEFAULT
  }

  const [currentTier = null] =
    tierValidations.find(([, validations]) => {
      let lt = false
      let gte = false

      if (validations[0] === null) gte = true
      if (validations[1] === null) lt = true

      gte = gte || socCo2eRateInHectares >= validations[0]
      lt = lt || socCo2eRateInHectares < validations[1]

      return Boolean(lt && gte)
    }) || []

  return currentTier
    ? FIELD_PROSPECTING.TIER_COLOR_CONFIG[currentTier]
    : FIELD_PROSPECTING.TIER_COLOR_CONFIG.DEFAULT
}

// this param is an array of two values, this is provided by the Slider
export const getTierFilter = (filters, ratingTiers) => {
  // We have here a pair of validations to get the Tier values (RATING_TIERS_IN_CO2E_TONS_PER_HECTARE_PER_YEAR),
  // it is an array of two values to get the ranges between which it is found, by this,
  // we can add values for the filters to send, increasing or decreasing the ranges to make the request to the api
  const gte = _.min(
    filters.map((value) => convertCO2equivalentToCarbon(ratingTiers[value][0]))
  )
  const lt = _.max(
    filters.map((value) => convertCO2equivalentToCarbon(ratingTiers[value][1]))
  )
  const foundNullishLT = filters.some((value) => ratingTiers[value][1] === null)
  if (foundNullishLT) return { gte }
  return { gte, lt }
}

export const getSocPotentialAverageInMetricTonsPerHectarePerYear = (
  fields = []
) => {
  const tierSum = fields.reduce(
    (acc, field) =>
      acc + field.soc_sequestration_rate_in_metric_tons_per_hectare_per_year,
    0
  )
  const tierAverage = tierSum / (fields.length || 1)
  return tierAverage
}

const convertCO2equivalentToCarbon = (CO2equivalent) => {
  return CO2equivalent / CARBON_TO_CO2E
}

export const convertCarbonToCO2equivalent = (carbon) => {
  if (!carbon) {
    return 0
  }
  return carbon * CARBON_TO_CO2E
}

/*
 * Given a list of fields from the POST /org/ag_fields_search endpoint,
 * calculate the total area of all the fields in hectares, ignoring any masks
 */
export const sumOfArea = (fields = [], unitSystem) => {
  const hectaresSum = fields.reduce(
    (acc, field) => acc + field.area_in_hectares,
    0
  )
  return convertAreaForDisplay(hectaresSum, unitSystem)
}

/**
 * Given a list of fields from the POST /org/ag_fields_search endpoint,
 * calculate the total area of all the fields in hectares, with masks applied
 */
export const sumOfAreaWithMasksApplied = (fields = [], unitSystem) => {
  const hectaresSum = fields.reduce(
    (acc, field) => acc + field.area_in_hectares_with_masks_applied,
    0
  )
  return convertAreaForDisplay(hectaresSum, unitSystem)
}

export const getTotalSocPotentialAlwaysMetricTons = (fields = []) => {
  const potentialSum = fields.reduce((acc, field) => {
    const sequestrationRate =
      field.soc_sequestration_rate_in_metric_tons_per_hectare_per_year || 0
    const area = field.area_in_hectares_with_masks_applied || 0
    return acc + sequestrationRate * area
  }, 0)

  return potentialSum * CARBON_TO_CO2E || 0
}

export const getSocPotential = (seqRating, fieldArea) => {
  if (isNaN(seqRating) || isNaN(fieldArea)) return null
  return seqRating * fieldArea
}

export const getSummary = (fields, unitSystem) => {
  // Calculate a summary of field data, from a list of fields
  const areaWithMasksApplied = sumOfAreaWithMasksApplied(fields, unitSystem)
  const area = sumOfArea(fields, unitSystem)
  const socPotentialTotal = getTotalSocPotentialAlwaysMetricTons(fields)

  return {
    // If there's a single field, show results with full precision
    // otherwise, round to the integer
    area: area,
    area_with_masks_applied: areaWithMasksApplied,
    fields_count: fields.length,
    soc_sequestration_potential_in_metric_tons_CO2e: socPotentialTotal,
  }
}

export const getFieldSocStockPerArea = (field, unitSystem) => {
  const { soc_stock_in_metric_tons, area_in_hectares_with_masks_applied } =
    field

  if (
    soc_stock_in_metric_tons == null ||
    area_in_hectares_with_masks_applied == null
  ) {
    return '---'
  }

  const socStockInCo2 = convertCarbonToCO2equivalent(soc_stock_in_metric_tons)
  const area = convertAreaForDisplay(
    area_in_hectares_with_masks_applied,
    unitSystem
  )

  return (socStockInCo2 / area).toFixed(2)
}
