import './sider.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Badge from '@mui/material/Badge'
import {
  FEATURES,
  TRACKED_ELEMENT,
  AMPLITUDE_EVENT,
  TAB_NAMES,
} from '../../constants'
import OrganizationList from './OrganizationList'
import { loggedIn } from '../../selectors'
import { logout } from '../../redux/user/actions'
import { isEmpty } from 'lodash'
import ProjectModal from '../ProjectModal'
import {
  Menu as MuiMenu,
  IconButton,
  MenuItem,
  Box,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  SvgIcon,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Add from '@mui/icons-material/Add'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import LanOutlinedIcon from '@mui/icons-material/LanOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Drawer, { MuiListItemButton } from './MuiDrawer'
import useAmplitudeTracking from '../../hooks/useAmplitudeTracking'
import { useTabHandler } from '../../hooks/useTabHandler'
import { Flagged } from '../Flagged'
import { FEATURE_FLAG } from '../../constants/features'
import { useProjectUtilities } from '../../hooks/useProjectUtilities'
import { ReactComponent as SiderbarUploadField } from '../../assets/img/sidebar-upload-fields.svg'
import { ReactComponent as SiderbarFields } from '../../assets/img/sidebar-fields.svg'
import { ReactComponent as SiderbarFieldProspecting } from '../../assets/img/sidebar-field-prospecting.svg'
import { ReactComponent as SiderbarBoundaryEditor } from '../../assets/img/sidebar-boundary-editor.svg'
import { ReactComponent as SiderbarSOC } from '../../assets/img/sidebar-soc.svg'
import { ReactComponent as SiderbarSamplin } from '../../assets/img/sidebar-samplin.svg'

const Sider = () => {
  const { trackEvent } = useAmplitudeTracking()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { fetchProjects, projectList: projects } = useProjectUtilities()
  const MuiSvgIcon = 24
  const MuiIconSize = '28px'
  const [createProjectOpen, setCreateProjectOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const MenuOpen = Boolean(anchorEl)
  const currentUser = useSelector((state) => state.user.currentUser)
  const isLoggedIn = useSelector(loggedIn)
  const isAwaitingCallback = useSelector(
    (state) => !isEmpty(state.user.identity) && isEmpty(state.user.currentUser)
  )
  const { myOrganizations, currentOrganization } = useSelector(
    (state) => state.organization
  )
  const { permissions } = useSelector((state) => state.permissions)

  const { selectedItem } = useTabHandler()

  const doLogout = () => {
    handleCloseMenu()
    dispatch(logout(history))
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const handleOpenMenu = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setOpenDrawer(false)
  }

  const handleTrackButton = (name) => {
    trackEvent(AMPLITUDE_EVENT.ELEMENT_CLICKED, {
      type: TRACKED_ELEMENT.BUTTON,
      name: name,
    })
  }

  const handleOpenModal = () => {
    handleTrackButton('new_project')
    setCreateProjectOpen(true)
    handleDrawerClose()
  }

  useEffect(() => {
    if (currentOrganization?.id) {
      fetchProjects()
    }
  }, [currentOrganization?.id])

  // TODO: fix me
  const showMenu =
    (currentUser && currentUser.user_role === 'admin') ||
    (myOrganizations && myOrganizations.length > 0)
  const showAdminMenu = currentUser && currentUser.user_role === 'admin'

  // Don't ever show on login page
  if (['/login', '/forgot-password'].indexOf(location.pathname) > -1) {
    return null
  }

  return (
    (isAwaitingCallback || isLoggedIn) && (
      <Drawer
        variant="permanent"
        open={openDrawer}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <List sx={{ flexGrow: 1 }}>
          <ListItem className="logoContainer" disablePadding>
            <div className="logo" />
          </ListItem>
          <Divider />
          {isLoggedIn && (
            <>
              {currentUser && currentUser !== '' && (
                <>
                  {showMenu && permissions && (
                    <>
                      {myOrganizations.length > 1 && (
                        <ListItem disablePadding sx={{ margin: '20px 0' }}>
                          <OrganizationList hide={!openDrawer} />
                        </ListItem>
                      )}
                      {permissions[FEATURES.UPLOAD_FIELDS] &&
                        permissions[FEATURES.UPLOAD_FIELDS].includes(
                          'list'
                        ) && (
                          <ListItem disablePadding>
                            <Link to="/upload-fields">
                              <MuiListItemButton
                                open={openDrawer}
                                selected={
                                  selectedItem === TAB_NAMES['upload-fields']
                                }
                                icon={
                                  <SvgIcon>
                                    <SiderbarUploadField
                                      width={MuiSvgIcon}
                                      height={MuiSvgIcon}
                                    />
                                  </SvgIcon>
                                }
                              >
                                <ListItemText
                                  primary={'Upload Fields'}
                                  sx={{ opacity: openDrawer ? 1 : 0 }}
                                />
                              </MuiListItemButton>
                            </Link>
                          </ListItem>
                        )}
                      {permissions[FEATURES.FIELDS] &&
                        permissions[FEATURES.FIELDS].includes('list') && (
                          <>
                            <ListItem disablePadding>
                              <Link to="/fields">
                                <MuiListItemButton
                                  open={openDrawer}
                                  selected={selectedItem === TAB_NAMES.fields}
                                  icon={
                                    <SvgIcon>
                                      <SiderbarFields
                                        width={MuiSvgIcon}
                                        height={MuiSvgIcon}
                                      />
                                    </SvgIcon>
                                  }
                                >
                                  <ListItemText
                                    primary={'Field Manager'}
                                    sx={{ opacity: openDrawer ? 1 : 0 }}
                                  />
                                </MuiListItemButton>
                              </Link>
                            </ListItem>
                            <ListItem disablePadding>
                              <Link to="/field-prospecting">
                                <MuiListItemButton
                                  open={openDrawer}
                                  selected={
                                    selectedItem ===
                                    TAB_NAMES['field-prospecting']
                                  }
                                  icon={
                                    <SvgIcon>
                                      <SiderbarFieldProspecting
                                        width={MuiSvgIcon}
                                        height={MuiSvgIcon}
                                      />
                                    </SvgIcon>
                                  }
                                >
                                  <ListItemText
                                    primary={'Field Prospecting'}
                                    sx={{ opacity: openDrawer ? 1 : 0 }}
                                  />
                                </MuiListItemButton>
                              </Link>
                            </ListItem>
                            <Flagged
                              visible={!FEATURE_FLAG.REACT_APP_FF_UI_PROJECTS}
                            >
                              <ListItem disablePadding>
                                <Link to="/boundary-editor">
                                  <MuiListItemButton
                                    open={openDrawer}
                                    selected={
                                      selectedItem ===
                                      TAB_NAMES['boundary-editor']
                                    }
                                    icon={
                                      <SvgIcon>
                                        <SiderbarBoundaryEditor
                                          width={MuiSvgIcon}
                                          height={MuiSvgIcon}
                                        />
                                      </SvgIcon>
                                    }
                                  >
                                    <ListItemText
                                      primary={'Boundary Editor'}
                                      sx={{ opacity: openDrawer ? 1 : 0 }}
                                    />
                                  </MuiListItemButton>
                                </Link>
                              </ListItem>
                              <ListItem disablePadding>
                                <Link to="/soc-stock">
                                  <MuiListItemButton
                                    open={openDrawer}
                                    selected={
                                      selectedItem === TAB_NAMES['soc-stock']
                                    }
                                    icon={
                                      <SvgIcon>
                                        <SiderbarSOC
                                          width={MuiSvgIcon}
                                          height={MuiSvgIcon}
                                        />
                                      </SvgIcon>
                                    }
                                  >
                                    <ListItemText
                                      primary={'SOC Stock L2'}
                                      sx={{ opacity: openDrawer ? 1 : 0 }}
                                    />
                                  </MuiListItemButton>
                                </Link>
                              </ListItem>
                            </Flagged>
                          </>
                        )}
                      {permissions[FEATURES.WORK_ORDERS] &&
                        permissions[FEATURES.WORK_ORDERS].includes('list') && (
                          <Flagged
                            visible={!FEATURE_FLAG.REACT_APP_FF_UI_PROJECTS}
                          >
                            <ListItem disablePadding>
                              <Link to="/unassigned-fields">
                                <MuiListItemButton
                                  open={openDrawer}
                                  selected={
                                    selectedItem ===
                                    TAB_NAMES['unassigned-fields']
                                  }
                                  icon={
                                    <SvgIcon>
                                      <SiderbarSamplin
                                        width={MuiSvgIcon}
                                        height={MuiSvgIcon}
                                      />
                                    </SvgIcon>
                                  }
                                >
                                  <ListItemText
                                    primary={'Sampling Ops'}
                                    sx={{ opacity: openDrawer ? 1 : 0 }}
                                  />
                                </MuiListItemButton>
                              </Link>
                            </ListItem>
                          </Flagged>
                        )}
                    </>
                  )}
                  {showAdminMenu && (
                    <ListItem disablePadding>
                      <Typography
                        noWrap
                        sx={{ color: theme.palette.misc.grey82, mb: 1 }}
                      >
                        Site Admin
                      </Typography>
                      <Link to="/organizations">
                        <MuiListItemButton
                          open={openDrawer}
                          selected={selectedItem === TAB_NAMES.organizations}
                          icon={
                            <LanOutlinedIcon sx={{ fontSize: MuiIconSize }} />
                          }
                        >
                          <ListItemText
                            primary={'Organizations'}
                            sx={{ opacity: openDrawer ? 1 : 0 }}
                          />
                        </MuiListItemButton>
                      </Link>
                    </ListItem>
                  )}
                </>
              )}
              <Flagged visible={FEATURE_FLAG.REACT_APP_FF_UI_PROJECTS}>
                <Divider sx={{ marginBottom: '20px' }} />
                {openDrawer && (
                  <ListItem disablePadding>
                    <ListItemText
                      primary={'Active Projects'}
                      sx={{
                        opacity: openDrawer ? 1 : 0,
                        color: theme.palette.primary.main,
                      }}
                    />
                  </ListItem>
                )}

                {projects?.map(({ id, name }, i) => (
                  <ListItem key={id} disablePadding className="project-item">
                    <Link to={`/projects/${id}/field-prospecting`} key={id}>
                      <ListItemButton
                        selected={location.pathname.includes(`/projects/${id}`)}
                      >
                        <Badge
                          variant="dot"
                          sx={{
                            minWidth: 0,
                            ml: openDrawer ? 0 : '16px',
                            mr: openDrawer ? 3 : 'auto',
                            justifyContent: 'center',
                            '& .MuiBadge-dot': {
                              backgroundColor:
                                theme.palette[`project${i > 4 ? 4 : i}`]?.main,
                            },
                          }}
                        />
                        <ListItemText
                          primary={name}
                          sx={{ opacity: openDrawer ? 1 : 0 }}
                          primaryTypographyProps={{ noWrap: true }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ))}

                <MuiListItemButton open={openDrawer} onClick={handleOpenModal}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                    }}
                  >
                    {openDrawer ? (
                      <AddBoxOutlinedIcon sx={{ fontSize: MuiIconSize }} />
                    ) : (
                      <Add sx={{ fontSize: MuiIconSize }} />
                    )}
                  </ListItemIcon>
                </MuiListItemButton>
              </Flagged>
            </>
          )}
        </List>

        <List>
          <ListItem disablePadding onMouseEnter={handleOpenMenu}>
            {currentUser && (
              <MuiListItemButton
                open={openDrawer}
                selected={selectedItem === TAB_NAMES.settings}
                sx={{ position: 'relative' }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : 'auto',
                    paddingLeft: openDrawer ? 0 : '20px',
                    justifyContent: 'center',
                  }}
                >
                  <SettingsOutlinedIcon sx={{ fontSize: MuiIconSize }} />
                </ListItemIcon>
                <ListItemText
                  primary={'Settings'}
                  sx={{ opacity: openDrawer ? 1 : 0 }}
                />
                <IconButton sx={{ padding: 0, opacity: openDrawer ? 1 : 0 }}>
                  <ChevronRightIcon />
                </IconButton>
                <MuiMenu
                  className="settings-menu"
                  sx={{ position: 'absolute', left: 234, top: 0 }}
                  anchorEl={anchorEl}
                  open={MenuOpen}
                  onClose={handleCloseMenu}
                  transitionDuration={{ appear: 500, enter: 500, exit: 0 }}
                >
                  <Box onMouseLeave={handleCloseMenu}>
                    <Link to="/user-settings">
                      <MenuItem onClick={handleCloseMenu}>
                        User Settings
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={doLogout}>Logout</MenuItem>
                  </Box>
                </MuiMenu>
              </MuiListItemButton>
            )}
          </ListItem>
        </List>
        {createProjectOpen && (
          <ProjectModal
            visible={createProjectOpen}
            setVisible={setCreateProjectOpen}
          />
        )}
      </Drawer>
    )
  )
}

export default Sider
