import moment from 'moment'

const HECTARES_TO_ACRES = 2.47105
export const getURLQueryParam = (param) => {
  const { search } = window.location
  const queryParam = new URLSearchParams(search)
  return queryParam.get(param)
}

export const dateFormatter = (timestring, format = 'lll') =>
  timestring && typeof timestring === 'number'
    ? moment.unix(timestring).format(format)
    : moment(timestring).format(format)

export const convertAreaForDisplay = (area, unitSystem) => {
  const convertedArea =
    unitSystem === 'imperial' ? area * HECTARES_TO_ACRES : area
  return convertedArea.toFixed(2) // Format to 2 decimal places and convert back to number
}
export const convertWeightForDisplay = (weight, unitSystem) =>
  (unitSystem == 'imperial' ? weight * 1.10231 : weight).toFixed(1)

export const convertMetricTonsForDisplay = (weight) => weight.toFixed(1)

export const searchLocally = (searchedValue, collection, key) => {
  const regex = new RegExp(searchedValue, 'gi')
  const valuesFound = []

  for (let i = 0; i < collection.length; i++) {
    const comparativeValue = key ? collection[i][key] : collection[i]
    if (comparativeValue.match(regex)) {
      valuesFound.push(collection[i])
    }
  }

  return valuesFound
}

export const convertAreaToFilter = (value, unitSystem) =>
  unitSystem == 'imperial' ? value / HECTARES_TO_ACRES : value

export const validateInputValues = ({ min, max, value }) =>
  value !== '' ? Math.max(min, Math.min(max, Number(value))) : value

export const differenceSet = (previousSet, currentSet) => {
  const parsedSet = new Set(previousSet)
  return currentSet.find((s) => !parsedSet.has(s))
}

/**
 * Converts a hexadecimal color code to its RGB equivalent.
 *
 * @param {string} hex - The hexadecimal color code to convert.
 * @return {Array<number>|undefined} An array containing the RGB values of the color, or undefined if the input is invalid.
 */
export const hexToRgb = (hex) => {
  if (!/^#?([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/.test(hex)) {
    return
  }

  const bigint = parseInt(hex.substring(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return [r, g, b]
}

export const downloadS3FileByURL = async (url, fileName) => {
  const response = await fetch(url)

  if (!response.ok)
    throw new Error(
      `An error occurred while downloading the current file, status code: ${response.status}`
    )

  const content = await response.blob()
  const name = fileName || 'default_file_name'

  return { name, content }
}

export const getOrgScopedName = (pathname) => {
  if (pathname.startsWith('/field-prospecting/ranches')) {
    return 'Field Prospecting'
  }

  const lastSlashIndex = pathname.lastIndexOf('/')
  const lastSegment = pathname.substring(lastSlashIndex + 1)

  switch (lastSegment) {
    case 'upload-fields':
      return 'Field Upload'
    case 'user-settings':
      return 'Settings'
    case 'field-prospecting':
      return 'Field Prospecting'
    case 'fields':
      return 'Field Manager'
    case 'organizations':
      return 'Organizations'
    case 'upload-progress':
      return 'Upload Progress'

    /** Only applicable when FEATURE_FLAGS.REACT_APP_FF_UI_PROJECTS: false */
    case 'boundary-editor':
      return 'Boundary Editor'
    case 'soc-stock':
      return 'SOC Stock L2'
    case 'unassigned-fields':
      return 'Sampling Ops'
    case 'sampling-stratification':
      return 'Sampling Ops'
    case 'work-orders':
      return 'Sampling Ops'

    default:
      return lastSegment
  }
}

export const isProjectPath = (pathname) => {
  return pathname.includes('projects')
}

/**
 * This function follows the composition pattern,
 * it helps to compose a result based on the composition of
 * several functions received as an argument
 *
 * @param  {...Function} fncs
 * @returns any
 */

export const composeFunction = (...fncs) => {
  return (arg) => {
    return fncs?.reduceRight((acc, fn) => {
      return fn(acc)
    }, arg)
  }
}
