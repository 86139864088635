import { REMOVE_COMPLETED_FIELD, SET_APP_ATTR, SET_COMPLETED_FIELD } from '.'

export const setSelectedItem = ({ selectedItem }) => ({
  type: SET_APP_ATTR,
  selectedItem,
})

export const setCompletedField = ({ completedField }) => ({
  type: SET_COMPLETED_FIELD,
  completedField,
})

export const removeCompletedField = ({ completedFieldId }) => ({
  type: REMOVE_COMPLETED_FIELD,
  completedFieldId,
})
