import { omit } from 'lodash'

export const SET_APP_ATTR = 'app/SET_APP_ATTR'
export const SET_COMPLETED_FIELD = 'app/SET_COMPLETED_FIELD'
export const REMOVE_COMPLETED_FIELD = 'app/REMOVE_COMPLETED_FIELD'

const initialState = {
  selectedItem: null,
  // this is a attributte to store the completed fields
  // all these functions are only for frontend
  completedFields: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_ATTR:
      return {
        ...state,
        ...omit(action, 'type'),
      }
    case SET_COMPLETED_FIELD:
      return {
        ...state,
        completedFields: [
          ...(state?.completedFields || []),
          action.completedField,
        ],
      }
    case REMOVE_COMPLETED_FIELD:
      return {
        ...state,
        completedFields: [...(state?.completedFields || [])].filter(
          ({ ag_field_uuid }) => ag_field_uuid !== action.completedFieldId
        ),
      }
    default:
      return state
  }
}

export default reducer
