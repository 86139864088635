import MapboxDraw from '@mapbox/mapbox-gl-draw'
import * as MapboxDrawWaypoint from 'mapbox-gl-draw-waypoint'

import { getTierColor } from './socTier'
import theme from '../assets/scss/variables.scss'
import { MINIMUM_NUMBER_OF_VERTICES } from '../constants'
import { composeFunction } from './common'

export const fieldBoundary = (isFieldSelected, color) => ({
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': color ?? theme.whiteColor,
    'line-width': isFieldSelected ? 6 : 1,
  },
})
export const vectorLines = (color) => ({
  type: 'line',
  paint: {
    'line-width': 2,
    'line-color': color,
  },
})

export const maskBoundary = (color) => ({
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': color,
    'line-width': 4,
  },
})

export const fieldFill = (isAnyFieldSelected, field, ratingTiers) => {
  const {
    selected: isFieldSelected,
    soc_sequestration_rate_in_metric_tons_per_hectare_per_year: socValue,
  } = field

  return {
    type: 'fill',
    paint: {
      'fill-color': socValue
        ? getTierColor(socValue, ratingTiers).color
        : '#fafafa',
      'fill-opacity': !isAnyFieldSelected || isFieldSelected ? 0.85 : 0.45,
    },
  }
}

export const maskFill = (color, fullOpacity) => {
  return {
    type: 'fill',
    paint: {
      'fill-color': color,
      'fill-opacity': fullOpacity ? 1 : 0.4,
    },
  }
}

export const buildMultiPolygon = (geometry, id) => {
  return {
    geometry,
    id,
    type: 'Feature',
  }
}

export const parseGeometry = (coordinates, index) => {
  const geometry = {
    type: 'Polygon',
    coordinates,
  }

  return buildMultiPolygon(geometry, index)
}

/**
 * Generates an array of polygons based on the given array of masks.
 * Each of these polygons corresponds to a different layer and will be what will finally be rendered on the map
 * @param {Array} masks - The array of masks to generate polygons from.
 * @return {Array} An array of polygons with additional properties.
 */

export const generatePolygons = (masks) => {
  return masks.flatMap((mask) =>
    mask.geometry.coordinates.map((coordinate, index) => {
      const newPolygon = parseGeometry(coordinate, index)
      return {
        ...newPolygon,
        mask_type: mask.mask_type,
        index,
        mask_id: mask.id,
        id: `${index}-${mask.id}`,
      }
    })
  )
}

export const drawMapStyles = ({
  background = 'transparent',
  backgroundOpacty = 0.45,
  lineColor = theme.whiteColor,
  borderPointColor = theme.whiteColor,
  pointColor = theme.whiteColor,
} = {}) => {
  return [
    // line stroke
    {
      id: 'gl-draw-line',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': theme.whiteColor,
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    // polygon fill, polygon background (selected or not)
    {
      id: 'gl-draw-polygon-fill',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      paint: {
        'fill-color': background,
        'fill-outline-color': 'transparent',
        'fill-opacity': backgroundOpacty,
      },
    },
    // polygon mid points, points enabled to create new points in a polygon
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
      paint: {
        'circle-radius': 5,
        'circle-color': theme.otherYellow,
      },
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': lineColor,
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    // vertex point halos, border of each point in the polygon
    {
      id: 'gl-draw-polygon-and-line-vertex-halo-active',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': borderPointColor,
      },
    },
    // vertex points, each ponit in the polygon
    {
      id: 'gl-draw-polygon-and-line-vertex-active',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': pointColor,
      },
    },
    // this style is not used at the moment, but shows the same styles as a selected polygon
    // line stroke
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': theme.blackColor,
        'line-width': 3,
      },
    },
    // polygon fill
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      paint: {
        'fill-color': background,
        'fill-outline-color': 'transparent',
        'fill-opacity': backgroundOpacty,
      },
    },
    // polygon outline
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': lineColor,
        'line-width': 3,
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-trash-active',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': pointColor,
      },
    },
  ]
}

export const landcover = {
  id: 'landcover',
  source: 'mapbox-terrain',
  'source-layer': 'landcover',
  type: 'fill',
  paint: {
    'fill-color': theme.terrainFillColor,
    'fill-outline-color': theme.terrainOutlineColor,
  },
}

export const hillshade = {
  id: 'hillshade',
  source: 'mapbox-terrain',
  'source-layer': 'hillshade',
  type: 'fill',
  paint: {
    'fill-color': theme.terrainFillColor,
    'fill-outline-color': theme.terrainOutlineColor,
  },
}

export const contour = {
  id: 'contour',
  source: 'mapbox-terrain',
  'source-layer': 'contour',
  type: 'line',
  paint: {
    'line-color': theme.whiteColor,
  },
}

export const strataFill = {
  id: 'strata-fill',
  type: 'fill',
  layout: {},
  source: 'strata',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': 0.5,
  },
}

export const getStarataFeature = (fieldStrata = []) => {
  return {
    type: 'FeatureCollection',
    features: (() => {
      let strata = []
      for (var i = 0; i < fieldStrata.length; i++) {
        strata.push({
          geometry: fieldStrata[i].geometry,
          properties: {
            ...fieldStrata[i].id,
            color: theme['project' + (i % 4)],
          },
        })
      }
      return strata
    })(),
  }
}

export const getTextLayer = (tag, color) => ({
  layout: {
    'text-field': ['get', tag],
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-radial-offset': 0.5,
    'text-justify': 'auto',
  },
  paint: {
    'text-color': color || theme.whiteColor,
  },
})

// Patching DirectSelect to remove polygon vertices validating when we have a feature with 3 vertices
function patchDirectSelect(DirectSelect) {
  // copy all the methods from the modes
  const DirectSelectPatched = {
    ...DirectSelect,
  }

  // overwrite the existing functions and add the new Feature validation
  // state contains the value of the feature
  DirectSelectPatched.onTrash = function (state) {
    /**
     * feature coordinates do not contain the closing vertices,
     * there are only the points that are shown on the map,
     * that is why we validate with the number 3, otherwise,
     * we would have to validate by 4 if it contained the closing point at the beginning and end of the polygon.
     */

    if (state?.feature?.coordinates?.[0]?.length > MINIMUM_NUMBER_OF_VERTICES) {
      /**
       * the rest of code is the same used to delete vertices, you can check it in the repo of the library
       * mapbox-gl-draw
       *
       * using call action to execute the function in a specific context
       */
      DirectSelect?.onTrash?.call(this, state)
    }
  }

  return DirectSelectPatched
}

// this function copies all the modes and call the patchDirectSelect function to overwrite some modes
export function customPatchedModes(modes) {
  return {
    ...modes,
    [MapboxDraw.constants.modes.DIRECT_SELECT]: patchDirectSelect(
      modes[MapboxDraw.constants.modes.DIRECT_SELECT]
    ),
  }
}

export const getModes = (...customModes) => {
  const composedModes = composeFunction(...customModes)
  return composedModes(MapboxDraw.modes)
}

export const mapModes = {
  BOUNDARY_EDITOR: getModes(MapboxDrawWaypoint.enable, customPatchedModes),
}
